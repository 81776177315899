import './src/styles/main.css'
import '@fontsource/space-grotesk/300.css'
import '@fontsource/space-grotesk/400.css'
import '@fontsource/space-grotesk/700.css'

const loadScript = (id, src, defer = false) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.id = id
    script.src = src
    script.async = true
    if (defer) script.defer = true

    script.onload = resolve
    script.onerror = reject

    document.head.appendChild(script)
  })

export const onClientEntry = () => {
  Promise.all([
    loadScript(
      'cookieyes',
      'https://cdn-cookieyes.com/client_data/07d6216470f07ffc186cbd39/script.js'
    ),
    loadScript('hs-script-loader', '//js.hs-scripts.com/49404459.js', true),
  ]).catch((error) => {
    console.error('Error loading external scripts:', error)
  })

  window.dataLayer = window.dataLayer || []
}
